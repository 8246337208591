import ImgixClient from '@imgix/js-core';

const ghostClient = new ImgixClient({
  domain: 'g-marlb.imgix.net',
  // secureURLToken: '<SECURE TOKEN>',
});

const ghostNelsonClient = new ImgixClient({
  domain: 'nelson.imgix.net',
  // secureURLToken: '<SECURE TOKEN>',
});

const directusClient = new ImgixClient({
  domain: 'd-marlb.imgix.net',
  // secureURLToken: '<SECURE TOKEN>',
});

const ghostKereNelsonClient = new ImgixClient({
  domain: 'kere-nelson.imgix.net',
  // secureURLToken: '<SECURE TOKEN>',
});

export default function imgix(imagePath, width, height) {
  if (imagePath === null || imagePath === '') {
    return 'https://nelson.imgix.net/placeholder.jpg';
  }

  if (imagePath.includes('//www.gravatar.com/avatar/')) {
    const secure = 'https:';
    const joined = secure.concat(imagePath);

    try {
      const url = new URL(joined);
      return url;
    } catch (_) {
      return '';
    }
  }

  const url = new URL(imagePath);

  if (url.pathname.includes('/file/kere-marlb/')) {
    // Ghost - Backblaze upload path.
    const path = url.pathname.replace('/file/kere-marlb/', '');
    const img = ghostClient.buildURL(path, {
      fill: 'blur',
      fit: 'crop',
      h: height,
      w: width,
    });

    return img;
  }

  if (url.pathname.includes('/file/images-nelson-app/')) {
    // Ghost - Backblaze upload path.
    const path = url.pathname.replace('/file/images-nelson-app/', '');
    const img = ghostNelsonClient.buildURL(path, {
      fill: 'blur',
      fit: 'crop',
      h: height,
      w: width,
    });

    return img;
  }

  if (url.pathname.includes('/file/kere-nelson/')) {
    // Ghost - Backblaze upload path.
    const path = url.pathname.replace('/file/kere-nelson/', '');
    const img = ghostKereNelsonClient.buildURL(path, {
      fill: 'blur',
      fit: 'crop',
      h: height,
      w: width,
    });

    return img;
  }

  if (url.pathname.includes('/assets/')) {
    // Directus - Assets endpoint.
    const path = url.pathname.replace('/assets/', '');
    const img = directusClient.buildURL(path, {
      fill: 'solid',
      fit: 'fill',
      h: height,
      w: width,
    });
    return img;
  }

  return url;
}
