/* global instantsearch */

import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import imgix from './imgix';

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: 'gvS6SgQbwcmsd5sSof6RXpFRRgxhVPQwBWdWNUgenvsMUeqS', // Be sure to use an API key that only allows searches, in production
    nodes: [
      {
        host: 'search.kere.co.nz',
        protocol: 'https',
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  //  filterBy is managed and overridden by InstantSearch.js. To set it, you want to use one of the filter widgets like refinementList or use the `configure` widget.
  additionalSearchParameters: {
    queryBy: 'title',
  },
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

const search = instantsearch({
  searchClient,
  indexName: 'kere-test',
});

search.addWidgets([
  instantsearch.widgets.searchBox({
    container: '#searchbox',
  }),
  instantsearch.widgets.configure({
    hitsPerPage: 8,
  }),
  instantsearch.widgets.hits({
    container: '#hits',
    templates: {
      item(item) {
        return `<div>
          <img src="${imgix(item.feature_image, 254, 177)}" alt="${
          item.name
        }" height="100" />
          <div class="hit-name">
          ${instantsearch.highlight({
            attribute: 'name',
            highlightedTagName: 'mark',
            item,
          })}
          ${item.title}
          </div>
        </div>`;
      },
    },
  }),
  instantsearch.widgets.pagination({
    container: '#pagination',
  }),
]);

search.start();
